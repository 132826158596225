import { Responsive, keyboardOutline } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useState } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';

/**
 * If the child text contains less than the minimum character count, show the children in their
 * entirity without the expandable fade component controls
 */
const MINIMUM_CHARACTER_COUNT = 800;

interface ExpandableFadeContentProps extends ClassNameProps {
  trackingContext: string;
  shownHeight?: Responsive<number>;
  characterCount: number;
  containerStyles?: SxStyleProp;
}

export const ExpandableFadeContent: React.FC<ExpandableFadeContentProps> = ({
  trackingContext,
  children,
  shownHeight = [120, 240],
  characterCount,
  className,
  containerStyles,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const useExpandableContainer = characterCount >= MINIMUM_CHARACTER_COUNT;

  const handleReadMoreClick = () => {
    setIsExpanded(true);
  };

  const handleShowLessClick = () => {
    setIsExpanded(false);
  };

  return (
    <div sx={containerStyles}>
      <div
        className={className}
        sx={
          useExpandableContainer
            ? {
                position: 'relative',
                maxHeight: isExpanded ? 'auto' : shownHeight,
                overflow: 'hidden',
                '& > div > div > *:last-child': {
                  marginBottom: 0,
                },

                ...(!isExpanded && {
                  '::after': {
                    content: "''",
                    position: 'absolute',
                    display: 'block',
                    bottom: '0',
                    left: '0',
                    height: '50px',
                    width: '100%',
                    backgroundImage: `linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgb(255,255,255) 100%)`,
                  },
                }),
              }
            : {}
        }
      >
        {children}
      </div>

      {useExpandableContainer && (
        <WithClickTracking
          action={trackingContext}
          label={isExpanded ? 'showLess' : 'readMore'}
        >
          <div sx={{ marginTop: '2xs' }}>
            <button
              data-id="expandable-button"
              type="button"
              onClick={isExpanded ? handleShowLessClick : handleReadMoreClick}
              sx={{
                color: 'link',
                ...keyboardOutline,
              }}
            >
              {isExpanded ? t('linkLabels.showLess') : t('linkLabels.readMore')}
            </button>
          </div>
        </WithClickTracking>
      )}
    </div>
  );
};
